html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

//.bgstyle {
//  /* background: linear-gradient(136deg, #1ad7c0, #8E44AD, #ff9b11, #1F3A93, #ddd8c6, #000000, #000000);  */
//  /*background: linear-gradient(
//    136deg,
//    #4484ce,
//    #1ad7c0,
//    #ff9b11,
//    #9b59b6,
//    #ff7f7f,
//    #ecf0f1
//  );*/
//
//  background-size: 1200% 1200%;
//  -webkit-animation: BackgroundAnimation 70s ease infinite;
//  -moz-animation: BackgroundAnimation 70s ease infinite;
//  -o-animation: BackgroundAnimation 70s ease infinite;
//  animation: BackgroundAnimation 70s ease infinite;
//  height: 100%;
//}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);

  visibility: visible;
  transition: all 0.2s ease-in;;
}

.navbar-hidden {
  visibility: hidden;
  transition: all 0.2s ease-out;
  transform: translate(0, -100%);
}

.brand {
  color: #363636 !important;
}

.navbar-link {
  transition: color, 0.2s;
  //background-color: rgba(0, 0, 0, 0.3); //rgba(82, 195, 145, 0.5); // #57CF8D;
  color: white;
  mix-blend-mode: overlay;
  background-blend-mode: luminosity;

  &:hover {
    background-color: rgba(0, 0, 0, 0.10);
  }
}

.navbar-dropdown {
  background-color: rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba(0, 0, 0, 0.10);
  }

  &::selection {
    background-color: rgba(0, 0, 0, 0.10)
  }

  &:active {
    color: white !important;
  }
}

.dropdown-link {
  background-color: rgba(0, 0, 0, 0.05);
}

.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;

  &:hover {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
  }
}

.social-icons {
  color: black;
  opacity: 0.6;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;

  &:hover {
    opacity: 1;
    //transform: scale(1.02);
    transition: all 0.8s;
  }
}

.skills-container {

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;

  @media screen and (max-width: 1250px) {
    flex-wrap: wrap;
  }
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: black !important;
  background-color: whitesmoke !important;
  mix-blend-mode: normal !important;
  background-blend-mode: normal !important;
}

.nav-tabs .nav-link {
  border-bottom: 1px solid #b6bbc1;
}

.nav-tabs .nav-link.active {
  border-top: 1px solid #b6bbc1;
  border-left: 1px solid #b6bbc1;
  border-right: 1px solid #b6bbc1;

  &:hover {
    border-bottom: 0;
  }
}


.nav-tabs .nav-link:hover {
  //color: gray !important;
  background-color: rgba(245, 245, 245, 0.9) !important;
  opacity: 0.9 !important;
  border: 1px solid #b6bbc1;
}


.center-navbar {
  width: 30%;
  margin-left: 35%;
}

.fadein {
  &:hover {
    color: black;
    transition: all 0.9s;
  }
}

.scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  border-radius: 100%;

  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  //color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 2rem 3rem rgba(black, 0.175) !important;
    transform: translateY(-2px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}

.app-info-header {
  display: grid;
  grid-template-columns: 15fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,64L80,69.3C160,75,320,85,480,74.7C640,64,800,32,960,32C1120,32,1280,64,1360,80L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>

.card-inner-left {
  border-right: dashed;
  border-width: 1px;
}

.skills-tabs {
  flex-grow: 1;
}

.skills-tab {
  flex-grow: 1;
  text-align: center;
}

.progress {
  transform-origin: left;

  & > * {
    transform: scaleX(0);
  }
}

.progress-bar-animation {
  @extend .progress;

  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}

.double-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-left: 1.5rem;
  margin-top: 1rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(8, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-left: 5rem;
  }
}

.quad-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-left: 1.5rem;
  margin-top: 1rem;
  padding-left: 1rem;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
}

// sliding background
//html {
//  height:100%;
//}
//
//body {
//  margin:0;
//}

.sl-bg {
  animation: slide 3*7s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom: 0;
  left: -50%;
  opacity: .5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.sl-bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4*7s;
}

.sl-bg3 {
  animation-duration: 5*7s;
}

.sl-content {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: .25em;
  box-shadow: 0 0 .25em rgba(0, 0, 0, .25);
  box-sizing: border-box;
  left: 50%;
  padding: 10vmin;
  text-align: center;
  top: 50%;
  //transform:translate(-50%, -50%);
}

//
//h1 {
//  font-family:monospace;
//}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}