div.clock-info {
  text-align: center;
  font-size: 4rem;
  line-height: 3.6rem;
  /*position: relative;*/
  color: #379683;
  letter-spacing: 2px;
}

div.clock {
  text-align: center;
  font-size: 9rem;
  /*position: relative;*/
  color: #e8f2d9;
  line-height: 8rem;
  /*letter-spacing: 2px;*/
}

div.screen-center {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  @media screen and (max-width: 481px) {
    margin-top: 20px;
  }

}